<script setup lang="ts">
import { ENDPOINT } from '@/config';
import type { ContentCarouselItemStoryblok } from '@/storyblok-types';

const elementRef = ref<HTMLDivElement | null>(null);
const videoRef = ref<HTMLVideoElement | null>(null);
const isVideoVisible = useElementVisibility(elementRef, { threshold: 0.7 });
const videoPlaying = ref(false);
const playPromise = ref<Promise<void> | null>(null);

withDefaults(defineProps<{
  item: ContentCarouselItemStoryblok;
  preload: boolean;
}>(), { preload: false, })

const { getStoryblokUrl } = useStoryblokData();

const playVideo = () => {
  if (videoRef.value) {
    playPromise.value = videoRef.value.play();
    playPromise.value?.catch();
    videoPlaying.value = true;
  }
};

const pauseVideo = async () => {
  await (playPromise.value ?? Promise.resolve());
  if (videoRef.value) {
    videoRef.value.pause();
    videoPlaying.value = false;
  }
};

watch(isVideoVisible, (isVisible) => {
  if (isVisible && !videoPlaying.value) {
    playVideo();
  } else {
    pauseVideo();
  }
});
</script>

<template>
  <div
    ref="elementRef"
    v-editable="item"
    class="group/carousel-item relative flex aspect-2/3 w-[250px] shrink-0 flex-col justify-end overflow-hidden rounded-lg bg-white bg-cover bg-center p-6 shadow-lg shadow-dark/10"
  >
    <NuxtResponsivePicture
      v-if="item.image?.filename"
      aspect-ratio="2/3"
      class="absolute! inset-0 transition-transform group-hover/carousel-item:scale-105"
      :alt="item.headline ?? ''"
      :enable-lazy-loading="!preload"
      :preload="preload"
      :provider="item.isAnimatedWebp ? '' : 'storyblok'"
      :src="item.image.filename"
      :width="250"
    />
    <video
      v-if="item.videoName"
      ref="videoRef"
      loop
      muted
      playsinline
      webkit-playsinline
      class="absolute inset-0 z-0 size-full object-cover object-center transition-transform group-hover/carousel-item:scale-105"
      preload="metadata"
    >
      <source
        v-if="item.videoName"
        type="video/mp4"
        :src="`${ENDPOINT.S3_BUCKET}/${item.videoName}.mp4#t=0.1`"
      />
    </video>
    <div class="absolute inset-0 z-10 bg-linear-to-b from-transparent from-50% to-dark/80 to-100%"></div>
    <div class="relative z-20 leading-tight text-white transition-transform group-hover/carousel-item:-translate-y-2">
      <strong
        v-if="item.headline"
        class="block"
        :class="{
          'inline whitespace-pre-line bg-white box-decoration-clone px-1 uppercase leading-normal text-dark': item.headlineHighlighted,
        }"
      >
        <FormattedMarkdown
          allow-text-formatting
          inline
          :content="item.headline"
        />
      </strong>
      <small
        v-if="item.description"
        class="mt-2 block font-medium opacity-80"
      >
        <FormattedMarkdown
          allow-text-formatting
          inline
          :content="item.description"
        />
      </small>
    </div>

    <NuxtLink
      v-if="item.link"
      class="absolute inset-0 z-30 size-full"
      :to="getStoryblokUrl(item.link)"
    />
  </div>
</template>
